.receive-page-container {
  width: 100vw;
  height: 100vh;
  /* text-align: center; */
  padding: 20px;
}

.book-box {
  text-wrap: wrap;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 1rem;
  border: 1px solid #ccc;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  width: auto;
  min-height: 50%;
  min-width: 20%;
  text-align: center;
  background-color: #f5f5f5;
  border-radius: 6px;
  margin-bottom: 1rem;
}

.book-box-container {
  min-height: 15vh;
}

.book-boxes-container {
  margin-left: 1rem;
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.form-control,
.form-control-plaintext {
  width: 50% !important;
  display: inline !important;
}

form {
  margin-left: 1rem;
}
