* {
  box-sizing: border-box;
}

.button-box {
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.landing-button {
  padding: 0;
  cursor: pointer;
  font-family: inherit;
  color: #2d1e2f;
  font-weight: 600;
  font-size: 5vh;
  background-color: #5ca4a9;
  border: 0;
  margin: 0.5rem;
  width: 46vw;
  height: 96vh;
  min-height: 22rem;
  min-width: 30rem;
  border-radius: 0.5rem;
  box-shadow: 1px 1px 5px rgba(51, 101, 138, 0.5);
  transition: color 0.3s linear 0ms;
  transition: --p 0.5s;
  background-image: linear-gradient(var(--e), #5ca4a9 var(--p), #2d1e2f);
}

.landing-button:hover {
  box-shadow: 0px 0px 5px #33658a;
  --p: -150%;
  color: white;
}

/* media queries */

@media (max-width: 992px) {
  .landing-button {
    min-height: 0;
    min-width: 0;
    width: 96vw;
    height: 46vh;
    --e: 0deg;
  }
}
@media (min-width: 993px) {
  .landing-button {
    --e: 180deg;
  }
}
@property --p {
  syntax: "<percentage>";
  inherits: false;
  initial-value: 100%;
}
@property --e {
  syntax: "<angle>";
  inherits: false;
  initial-value: 0deg;
}
