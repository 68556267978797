* {
  box-sizing: border-box;
}

.login-box {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.login-text {
  margin: 1rem;
  font: inherit;
  font-weight: 500;
  font-size: 1.5rem;
}

.profile-page-box {
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.profile-page-box > h1 {
  font: inherit;
  font-size: 2rem;
  font-weight: 500;
}
.bookDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  padding: 1rem;
  border: 1px solid #ccc;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  width: auto;
  min-width: 15%;
  text-align: center;
  background-color: #f5f5f5;
  border-radius: 6px;
  margin-bottom: 1rem;
}

.user-info > img {
  border-radius: 50%;
}

.user-books > h2 {
  text-decoration: underline;
  font-weight: 500;
  font-size: 1.8rem;
  margin-bottom: 1rem;
}

ol {
  padding: 0 !important;
  margin: 0;
  list-style-type: none;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
}
li {
  min-height: 100%;
}

.profile-page-add-btn,
.profile-page-logout-button {
  margin-bottom: 1rem;
  border: 1px solid #3a6164;
}

.profile-page-add-btn:hover,
.profile-page-logout-button:hover {
  box-shadow: 0px 0px 5px #3e6f72;
  border: none;
}

.bin-btn {
  border: none;
  background-color: #f5f5f5;
  height: 3rem;
  aspect-ratio: 0.9;
  margin-bottom: 0;
  margin-left: 1rem;
  border-radius: 20%;
}

.bin-btn:hover {
  background-color: #e8e8e8;
}
